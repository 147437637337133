import React, { useEffect, useState } from "react";
import axios from "axios";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

import TextField from "@mui/material/TextField";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object({
  endereco: Yup.string().required("Endereço é obrigatório!"),
  numero: Yup.string().required("Número é obrigatório ou digite S/N!"),
  complemento: Yup.string(),
  bairro: Yup.string().required("Bairro é obrigatório!"),
  cidade: Yup.string().required("Cidade é obrigatório!"),
  estado: Yup.string().required("Estado é obrigatório!"),
  cep: Yup.string()
    .min(8, "Cep deve ser 99999999")
    .max(10, "Cep deve ser 99999999")
    .required("CEP é obrigatório"),
});

export default function Endereco({ data, onSubmit }) {
  const [loadingCep, setLoadingCep] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchCEP = watch("cep");

  const pegarEnderecoCompleto = async (cep) => {
    setLoadingCep(true);
    await axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then(function (response) {
        // handle success

        const info = response.data;

        if (info.logradouro) setValue("endereco", info.logradouro);
        if (info.bairro) setValue("bairro", info.bairro);
        if (info.localidade) setValue("cidade", info.localidade);
        if (info.uf) setValue("estado", info.uf);

        setLoadingCep(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoadingCep(false);
      })
      .then(function () {
        // always executed
        setLoadingCep(false);
      });
  };

  useEffect(() => {
    if (watchCEP && watchCEP.length === 8) {
      pegarEnderecoCompleto(watchCEP);
    }
  }, [watchCEP]);

  const handleOnSubmit = (data) => {
    // console.log(data);
    // // console.log(data);
    // const info = {
    //   email: data.email,
    //   password: data.password,
    // };

    onSubmit(data);
  };

  useEffect(() => {
    // console.log(data);
    if (data) {
      setValue("cep", data.cep || "");
      setValue("endereco", data.endereco || "");
      setValue("numero", data.numero || "");
      setValue("complemento", data.complemento || "");
      setValue("bairro", data.bairro || "");
      setValue("cidade", data.cidade || "");
      setValue("estado", data.estado || "");
    }
  }, [data, setValue]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleOnSubmit)}
      noValidate
      sx={{ mt: 1, maxWidth: 300, width: "100%" }}
    >
      <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
        Qual endereço deseja receber?
      </Typography>
      <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
        Escolha um endereço de entrega
      </Typography>

      <FormControl fullWidth>
        <TextField
          size="small"
          type="number"
          margin="normal"
          fullWidth
          id="cep"
          label="CEP"
          name="cep"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loadingCep && <CircularProgress size={20} />}
              </InputAdornment>
            ),
            inputMode: "numeric",
          }}
          inputProps={{
            maxLength: 8,
          }}
          disabled={loadingCep}
          error={errors.cep ? true : false}
          {...register("cep")}
          helperText={errors && errors.cep && errors.cep.message}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          size="small"
          type="text"
          margin="normal"
          fullWidth
          id="endereco"
          label="Endereço"
          name="endereco"
          InputLabelProps={{
            shrink: true,
          }}
          error={errors.endereco ? true : false}
          {...register("endereco")}
          helperText={errors && errors.endereco && errors.endereco.message}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          type="text"
          margin="normal"
          fullWidth
          id="numero"
          label="Número"
          name="numero"
          error={errors.numero ? true : false}
          {...register("numero")}
          helperText={errors && errors.numero && errors.numero.message}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          type="text"
          margin="normal"
          fullWidth
          id="complemento"
          label="Complemento (Ex: Apt. 803)"
          name="complemento"
          error={errors.complemento ? true : false}
          {...register("complemento")}
          helperText={
            errors && errors.complemento && errors.complemento.message
          }
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          type="text"
          margin="normal"
          fullWidth
          id="bairro"
          label="Bairro"
          name="bairro"
          error={errors.bairro ? true : false}
          {...register("bairro")}
          helperText={errors && errors.bairro && errors.bairro.message}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          type="text"
          margin="normal"
          fullWidth
          id="cidade"
          label="Cidade"
          name="cidade"
          error={errors.cidade ? true : false}
          {...register("cidade")}
          helperText={errors && errors.cidade && errors.cidade.message}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          type="text"
          margin="normal"
          fullWidth
          id="estado"
          label="Estado"
          name="estado"
          error={errors.estado ? true : false}
          {...register("estado")}
          helperText={errors && errors.estado && errors.estado.message}
        />
      </FormControl>

      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Salvar endereço para próximos pedidos."
      />

      <FormControl fullWidth sx={{ mt: 5 }}>
        <Button type="submit" variant="contained" size="medium">
          Avançar
        </Button>
      </FormControl>
    </Box>
  );
}
