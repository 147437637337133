import React from "react";

import { AuthProvider } from "./AuthContext";
import { ThemeProvider } from "./ThemeContext";

const AppProvider = ({ children }) => (
  <ThemeProvider>
    <AuthProvider>{children}</AuthProvider>
  </ThemeProvider>
);

export default AppProvider;
