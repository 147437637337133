import { useState, useEffect } from "react";
import * as qs from "qs";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

import api from "../../../../../services/api";

export default function Pedras({
  data,
  service,
  includedServices,
  onSubmit,
  onCancel,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [tipos, setTipos] = useState([]);
  const [alianca1, setAlianca1] = useState("");
  const [alianca2, setAlianca2] = useState("");
  const [servicoTipo, setServicoTipo] = useState("");
  const [erroServicoTipo, setErroServicoTipo] = useState("");
  const [obs, setObs] = useState("");

  const handleSubmit = () => {
    setErroServicoTipo("");
    let info = {};

    info.servico = Number(service.value);
    info.info = service;
    info.tipo = "descricao";
    info.obs = "";
    info.alianca_1 = alianca1;
    info.alianca_2 = alianca2;

    info.obs = obs;

    if (!servicoTipo) {
      setErroServicoTipo("Tipo de Serviço é obrigatório");
    } else {
      info.servico_tipo_info = tipos.find((item) => item.id === servicoTipo);
      info.servico_tipo = servicoTipo;

      // console.log(info);
      onSubmit(info);
    }
  };

  useEffect(() => {
    console.log(service);
    const query = qs.stringify(
      {
        filters: {
          servico: {
            id: {
              $eq: service.value,
            },
          },
        },
        populate: "*",
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );

    setIsLoading(true);
    api
      .get(`/api/servico-tipos?${query}`)
      .then((resp) => {
        // setCampos(resp.data);
        // setIsLoading(false);
        setTipos(resp.data.data);
        console.log(resp.data.data);
      })
      .catch((err) => {
        // dsds
        // setIsLoading(false);
      })
      .then((resp) => {
        // setCampos(resp.data);
        setIsLoading(false);
      });
  }, [service]);

  useEffect(() => {
    const selecionado = includedServices.find(
      (item) => item.servico === service.value
    );

    if (selecionado) {
      if (selecionado.alianca_1) setAlianca1(selecionado.alianca_1);
      if (selecionado.alianca_2) setAlianca2(selecionado.alianca_2);
    }
  }, [includedServices, service]);

  return (
    <Box noValidate sx={{ mt: 1, maxWidth: 300, width: "100%" }}>
      <Dialog open={isLoading} sx={{ p: 3 }}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
        {service.label || "-"}
      </Typography>
      <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
        {service.ajuda || "Descreva todos os detalhes"}
      </Typography>
      <FormControl
        fullWidth
        sx={{ mb: 3 }}
        variant="outlined"
        error={erroServicoTipo ? true : false}
      >
        <InputLabel id="servico_tipo">Tipo</InputLabel>
        <Select
          labelId="servico_tipo"
          id="servico_tipo"
          // value={age}
          defaultValue=""
          label="Tipo"
          // onChange={handleChange}
          value={servicoTipo}
          onChange={(event) => {
            setServicoTipo(event.target.value);
          }}
        >
          {tipos &&
            tipos.map((item, idx) => (
              <MenuItem key={idx} value={item.id}>
                {item.attributes.nome}
                {item.attributes.preco ? ` (R$ ${item.attributes.preco})` : ""}
              </MenuItem>
            ))}
        </Select>
        {erroServicoTipo && <FormHelperText>{erroServicoTipo}</FormHelperText>}
      </FormControl>

      {data.mais_info.alianca1 && (
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            label={`Aliança 1 - Nº ${data.mais_info.alianca1}`}
            fullWidth
            value={alianca1}
            onChange={(event) => {
              setAlianca1(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      )}
      {data.mais_info.alianca2 && (
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            label={`Aliança 2 - Nº ${data.mais_info.alianca2}`}
            fullWidth
            value={alianca2}
            onChange={(event) => {
              setAlianca2(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      )}

      <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
        <TextField
          label="Observação"
          // defaultValue={textAreaContent}
          minRows={3}
          type="text"
          multiline
          value={obs}
          onChange={(event) => {
            setObs(event.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mb: 3 }}>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          size="medium"
          onClick={() => handleSubmit()}
          color="secondary"
        >
          Salvar
        </Button>
        <Button variant="contained" size="medium" onClick={() => onCancel()}>
          Cancelar
        </Button>
      </FormControl>
    </Box>
  );
}
