import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

export default function MultiOption({
  question,
  subtitle,
  options,
  onSubmit,
  values,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (values) setSelectedOptions(values);
  }, [values]);

  const handleSubmit = () => {
    // console.log(selectedOptions);
    onSubmit(selectedOptions);
  };

  const handleSelect = (value) => {
    const selectedConst = selectedOptions;

    if (selectedConst.includes(value)) {
      const newOptions = selectedConst.filter(
        (item) => item.value !== value.value
      );
      setSelectedOptions(newOptions);
    } else {
      // console.log("Tem q incluir");
      setSelectedOptions([...selectedConst, value]);
    }
  };

  return (
    <Box noValidate sx={{ mt: 1, maxWidth: 300, width: "100%" }}>
      <Typography sx={{ mt: 1, mb: subtitle ? 0 : 3, fontWeight: "bold" }}>
        {question}
      </Typography>
      {subtitle && (
        <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>{subtitle}</Typography>
      )}

      {options &&
        options.map((option, idx) => (
          <Button
            key={idx}
            onClick={() => handleSelect(option)}
            fullWidth
            variant={
              selectedOptions && selectedOptions.includes(option)
                ? "contained"
                : "outlined"
            }
            sx={{ mt: 1, mb: 1 }}
          >
            {option.label}
          </Button>
        ))}

      <FormControl fullWidth sx={{ mt: 5 }}>
        <Button
          onClick={() => handleSubmit()}
          variant="contained"
          size="medium"
        >
          Avançar
        </Button>
      </FormControl>
    </Box>
  );
}
