import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
//import { useBrand } from "../context/BrandContext";

export const PrivateRoute = () => {
  // const { path } = rest;

  const { user } = useAuth();

  return user ? <Outlet /> : <Navigate to="/auth/login" />;
};

export const PublicRoute = () => {
  // const { path } = rest;

  const { user } = useAuth();

  return !user ? <Outlet /> : <Navigate to="/" />;
};
