import React, { useState } from "react";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

import Logo from "../../../components/logo";

// Formulário
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useAuth } from "../../../context/AuthContext";

import Layout from "../../../layouts/default";

const schema = Yup.object({
  nome: Yup.string().required("Por favor insira seu nome").min(2).max(50),
  sobrenome: Yup.string()
    .required("Por favor insira seu sobrenome")
    .min(2)
    .max(50),
  doc: Yup.string().required("Por favor insira seu CPF/CNPJ").min(11).max(20),
  telefone: Yup.string()
    .required("Por favor insira seu Telefone")
    .min(11)
    .max(20),
  email: Yup.string()
    .email("Insira um e-mail válido")
    .required("Insira seu e-mail"),
  password: Yup.string()
    .required("Por favor insira uma senha")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "A senha teve conter no mínimo 8 caracteres, um Maiúsculo, um minusculo, um número e um caracter especial"
    ),
});

export default function SignUp() {
  const [success, setSuccess] = useState(false);
  const { loading, signUp } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    // console.log(data);
    const info = {
      username: data.email,
      email: data.email,
      password: data.password,
      nomeCompleto: data.nome + " " + data.sobrenome,
      doc: data.doc,
      telefone: data.telefone,
    };
    const getSuccess = await signUp(info);

    console.log(getSuccess);
    setSuccess(getSuccess);
  };

  return (
    <Layout sx={{ pl: 2, pr: 2 }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />
        <Typography sx={{ mt: 2 }} component="h1" variant="h5">
          Cadastre-se
        </Typography>
        <Typography component="h2" variant="h6">
          A melhor fábrica de alianças do Brasil
        </Typography>
        {!success ? (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="nome"
                  fullWidth
                  id="nome"
                  label="Nome"
                  autoFocus
                  error={errors.nome ? true : false}
                  {...register("nome")}
                  helperText={errors && errors.nome && errors.nome.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="sobrenome"
                  label="Sobrenome"
                  name="sobrenome"
                  error={errors.sobrenome ? true : false}
                  {...register("sobrenome")}
                  helperText={
                    errors && errors.sobrenome && errors.sobrenome.message
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="doc"
                  label="CPF/CNPJ"
                  name="doc"
                  error={errors.doc ? true : false}
                  {...register("doc")}
                  helperText={errors && errors.doc && errors.doc.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="phone"
                  id="telefone"
                  label="Telefone"
                  name="telefone"
                  error={errors.telefone ? true : false}
                  {...register("telefone")}
                  helperText={
                    errors && errors.telefone && errors.telefone.message
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  error={errors.email ? true : false}
                  {...register("email")}
                  helperText={errors && errors.email && errors.email.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  error={errors.password ? true : false}
                  {...register("password")}
                  helperText={
                    errors && errors.password && errors.password.message
                  }
                />
              </Grid>
              <Grid item xs={12}>
                Ao se cadastrar, você concorda com os{" "}
                <Link component={RouterLink} to="/auth/login" variant="body2">
                  Termos de Uso
                </Link>{" "}
                e{" "}
                <Link component={RouterLink} to="/auth/login" variant="body2">
                  Política de Privacidade
                </Link>
                .
              </Grid>
            </Grid>

            {loading ? (
              <LoadingButton fullWidth loading variant="outlined">
                Enviando
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading="true"
              >
                Cadastrar
              </Button>
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/auth/login" variant="body2">
                  Você ja tem conta? Acesse
                </Link>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <Typography
              sx={{
                mt: 1,
                mb: 0,
                ta: "center",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Sucesso
            </Typography>

            <Typography
              sx={{
                mt: 1,
                mb: 0,
                ta: "center",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Você foi cadastrado!
            </Typography>

            <Typography
              sx={{
                mt: 1,
                mb: 3,
                ta: "center",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Aguarde nossa aprovação
            </Typography>

            <FormControl fullWidth sx={{ mb: 5, alignItems: "center" }}>
              <CheckCircleOutlineIcon color="success" sx={{ fontSize: 140 }} />
            </FormControl>
            <Link component={RouterLink} to="/auth/login" variant="body2">
              Voltar
            </Link>
          </>
        )}
      </Box>
    </Layout>
  );
}
