import * as React from "react";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Layout from "../../../layouts/default";
import { Link as RouterLink } from "react-router-dom";

import Logo from "../../../components/logo";

// Formulário
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useAuth } from "../../../context/AuthContext";

const schema = Yup.object({
  email: Yup.string()
    .email("Insira um e-mail válido")
    .required("Insira seu e-mail"),
  password: Yup.string().required("Por favor insira uma senha"),
});

export default function SignIn() {
  const { loading, signIn } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // console.log(data);
    const info = {
      email: data.email,
      password: data.password,
    };

    signIn(info);
  };

  return (
    <Layout sx={{ pl: 2, pr: 2 }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />
        <Typography sx={{ mt: 2 }} component="h1" variant="h5">
          Entrar
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            error={errors.email ? true : false}
            {...register("email")}
            helperText={errors && errors.email && errors.email.message}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            error={errors.password ? true : false}
            {...register("password")}
            helperText={errors && errors.password && errors.password.message}
          />

          <FormControlLabel
            control={<Checkbox checked value="remember" color="primary" />}
            label="Continuar contectado"
          />

          {loading ? (
            <LoadingButton fullWidth loading variant="outlined">
              Entrando...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>
          )}
          <Grid container>
            <Grid item xs>
              <Link
                component={RouterLink}
                to="/auth/recuperar-senha"
                variant="body2"
              >
                Esqueceu a senha?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/auth/cadastrar" variant="body2">
                {"Não tem conta? Cadastre-se"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
}
