import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

import Descricao from "./servicos/descricao";
import Pedras from "./servicos/pedras";

export default function OneOption({ data, options, onSubmit }) {
  const [includedServices, setIncludeServices] = useState([]);
  const [selectedService, setSelectedService] = useState({});

  useEffect(() => {
    if (data.servicos) setIncludeServices(data.servicos);
  }, [data]);

  const handleSubmit = () => {
    onSubmit(includedServices);
  };

  const handleCancel = () => {
    setSelectedService({});
  };

  const handleDeleteService = (id) => {
    let includes = includedServices;

    includes = includes.filter((item) => item.servico !== id);

    setIncludeServices(includes);
  };

  const handleAddService = (service) => {
    let includes = includedServices;

    const isInArray = includes.some((item) => item.servico === service.servico);

    if (!isInArray) {
      includes.push(service);
      setIncludeServices(includes);
      setSelectedService({});
    } else {
      includes = includes.filter((item) => item.servico !== service.servico);
      includes.push(service);
      setIncludeServices(includes);
      setSelectedService({});
    }
  };

  return (
    <Box noValidate sx={{ mt: 1, maxWidth: 300, width: "100%" }}>
      {selectedService &&
        Object.keys(selectedService).length === 0 &&
        Object.getPrototypeOf(selectedService) === Object.prototype && (
          <>
            <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
              Deseja adicionar algum serviço?
            </Typography>
            <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
              Pode seleciona mais de um.
            </Typography>
          </>
        )}

      {selectedService &&
        Object.keys(selectedService).length === 0 &&
        Object.getPrototypeOf(selectedService) === Object.prototype &&
        options &&
        options.map((option, idx) => (
          <FormControl key={idx} fullWidth sx={{ mb: 0, flexDirection: "row" }}>
            <Button
              onClick={() => setSelectedService(option)}
              fullWidth
              sx={{ mt: 1, mb: 1 }}
              variant={
                includedServices &&
                includedServices.some((item) => item.servico === option.value)
                  ? "contained"
                  : "outlined"
              }
            >
              {option.label}
            </Button>

            {includedServices &&
              includedServices.some(
                (item) => item.servico === option.value
              ) && (
                <IconButton
                  onClick={() => handleDeleteService(option.value)}
                  aria-label="delete"
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
          </FormControl>
        ))}

      {selectedService &&
        selectedService.tipo &&
        selectedService.tipo === "descricao" && (
          <Descricao
            data={data}
            service={selectedService}
            includedServices={includedServices}
            onCancel={() => handleCancel()}
            onSubmit={(service) => handleAddService(service)}
          />
        )}

      {selectedService &&
        selectedService.tipo &&
        selectedService.tipo === "pedras" && (
          <Pedras
            data={data}
            service={selectedService}
            includedServices={includedServices}
            onCancel={() => handleCancel()}
            onSubmit={(service) => handleAddService(service)}
          />
        )}

      {selectedService &&
        Object.keys(selectedService).length === 0 &&
        Object.getPrototypeOf(selectedService) === Object.prototype && (
          <FormControl fullWidth sx={{ mt: 5 }}>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              size="medium"
            >
              Avançar
            </Button>
          </FormControl>
        )}
    </Box>
  );
}
