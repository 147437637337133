import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { Link } from "react-router-dom";

export default function OneOption({ data, createNew }) {
  return (
    <Box noValidate sx={{ mt: 1, maxWidth: 300, width: "100%" }}>
      <Typography
        sx={{
          mt: 1,
          mb: 0,
          ta: "center",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Sucesso
      </Typography>

      <Typography
        sx={{
          mt: 1,
          mb: 0,
          ta: "center",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Obrigado por realizar o pedido
      </Typography>

      <Typography
        sx={{
          mt: 1,
          mb: 3,
          ta: "center",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        O pedido foi enviado para linha de produção
      </Typography>

      <FormControl fullWidth sx={{ mb: 5, alignItems: "center" }}>
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 140 }} />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        {data.data.id && (
          <Button
            component={Link}
            to={`/pedido/${data.data.id}`}
            variant="contained"
            size="medium"
          >
            Acompanhar Pedido
          </Button>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <Button onClick={() => createNew()} variant="contained" size="medium">
          Fazer Novo Pedido
        </Button>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <Button component={Link} to="/" variant="outlined" size="medium">
          Voltar Para Home
        </Button>
      </FormControl>
    </Box>
  );
}
