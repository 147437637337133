import { useEffect } from "react";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useAuth } from "../../../../context/AuthContext";

const numeros = [
  10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
  29, 30, 31, 32, 33, 34, 35,
];

const schema = Yup.object({
  quantidade: Yup.string().required("Você deve escolhar se é Par ou Unidade"),
  peso: Yup.string().required("Peso é obrigatório"),
  largura: Yup.string().required("Largura é obrigatória"),
  espessura: Yup.string(),
  alianca1: Yup.string().required("A escolha da aliança 1 é obrigatória!"),
  alianca2: Yup.string().when("quantidade", {
    is: "2",
    then: Yup.string().required("A medida da Aliança 2 é obrigatória!"),
  }),
});

export default function FormDetalhes({ data, onSubmit, options }) {
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchPeso = watch("peso");
  const watchQuantidade = watch("quantidade");

  const handleOnSubmit = (data) => {
    // console.log(data);
    const info = {
      quantidade: data.quantidade,
      peso: data.peso,
      largura: data.largura,
      espessura: data.espessura,
      alianca1: data.alianca1,
      alianca2: data.alianca2,
    };

    // console.log(user);
    onSubmit(info);
  };

  useEffect(() => {
    // console.log(data);
    if (data) {
      setValue("quantidade", data.quantidade || "");
      setValue("peso", data.peso || "");
      setValue("largura", data.largura || "");
      setValue("espessura", data.espessura || "");
      setValue("alianca1", data.alianca1 || "");
      setValue("alianca2", data.alianca2 || "");
    }
  }, [data, setValue]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleOnSubmit)}
      noValidate
      sx={{ mt: 1, maxWidth: 300, width: "100%" }}
    >
      <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
        Mais informações
      </Typography>
      <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
        Preencha todos os detalhes
      </Typography>
      <FormControl
        fullWidth
        sx={{ mb: 3 }}
        error={errors.quantidade ? true : false}
      >
        <InputLabel id="quantidade_label">Par ou Unidade</InputLabel>
        <Controller
          name="quantidade"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              labelId="quantidade_label"
              id="quantidade"
              label="Seleciona Par ou Pé"
              {...register("quantidade")}
            >
              {options &&
                options.map((item, idx) => (
                  <MenuItem key={idx} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          )}
        />

        {errors && errors.quantidade && errors.quantidade.message && (
          <FormHelperText>{errors.quantidade.message}</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
        <TextField
          type="number"
          margin="normal"
          fullWidth
          id="peso"
          label="Peso"
          name="peso"
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
            inputMode: "numeric",
          }}
          error={errors.peso ? true : false}
          {...register("peso")}
          // helperText={errors && errors.peso && errors.peso.message}
        />
        <FormHelperText id="outlined-peso-helper-text">
          {errors && errors.peso && errors.peso.message ? (
            errors.peso.message
          ) : (
            <>
              Nesse peso é adicionado {user.falha ? user.falha : 10}% de falha
              <br />
              {watchPeso && (
                <b>
                  {(
                    watchPeso * (user.falha ? user.falha / 100 + 1 : 1.1)
                  ).toFixed(2)}
                  g (Peso + Peso Falha)
                </b>
              )}
            </>
          )}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <TextField
          type="number"
          margin="normal"
          fullWidth
          id="largura"
          label="Largura"
          name="largura"
          InputProps={{
            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            inputMode: "numeric",
          }}
          error={errors.largura ? true : false}
          {...register("largura")}
          helperText={errors && errors.largura && errors.largura.message}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <TextField
          type="number"
          margin="normal"
          fullWidth
          id="espessura"
          label="Espessura"
          name="espessura"
          InputProps={{
            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            inputMode: "numeric",
          }}
          error={errors.espessura ? true : false}
          {...register("espessura")}
          helperText={
            errors && errors.espessura && errors.espessura.message
              ? errors.espessura.message
              : "Não é possível conciliar espessura com o peso!"
          }
        />
      </FormControl>

      <FormControl
        fullWidth
        sx={{ mb: 3 }}
        error={errors.alianca1 ? true : false}
      >
        <InputLabel id="alianca1_label">Número da Aliança 1 Ø</InputLabel>
        <Controller
          name="alianca1"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              labelId="alianca1_label"
              id="alianca1"
              label="Número da Aliança 1 Ø"
              {...register("alianca1")}
            >
              {numeros.map((numero, idx) => (
                <MenuItem key={idx} value={numero}>
                  {numero}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        {errors && errors.alianca1 && errors.alianca1.message && (
          <FormHelperText>{errors.alianca1.message}</FormHelperText>
        )}
      </FormControl>

      {watchQuantidade > 1 && (
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.alianca2 ? true : false}
        >
          <InputLabel id="alianca2_label">Número da Aliança 2 Ø</InputLabel>
          <Controller
            name="alianca2"
            control={control}
            defaultValue=""
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  labelId="alianca2_label"
                  id="alianca2"
                  label="Número da Aliança 2 Ø"
                  {...register("alianca2")}
                >
                  {numeros.map((numero, idx) => (
                    <MenuItem key={idx} value={numero}>
                      {numero}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />

          {errors && errors.alianca2 && errors.alianca2.message && (
            <FormHelperText>{errors.alianca2.message}</FormHelperText>
          )}
        </FormControl>
      )}

      <FormControl fullWidth sx={{ mb: 3 }}>
        <Button variant="contained" size="medium" type="submit">
          Avançar
        </Button>
      </FormControl>
    </Box>
  );
}
