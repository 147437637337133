import { useState, useEffect } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import Layout from "../../../layouts/default";

import api from "../../../services/api";

import Upload from "./etapas/upload";
import OneOption from "./etapas/oneOption";
import MultiOption from "./etapas/multiOption";
import FormDetalhes from "./etapas/formDetalhes";
import Servicos from "./etapas/servicos";
import Enderecos from "./etapas/enderecos";
import Resumo from "./etapas/resumo";
import TextArea from "./etapas/textarea";
import Sucesso from "./etapas/sucesso";

import { Link } from "react-router-dom";

export default function Novo() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState("upload");
  const [campos, setCampos] = useState({});
  const [resultData, setResultData] = useState({});

  const steps = [
    "upload",
    "materia_prima",
    "materia_prima_tipo",
    "perfil",
    "mais_info",
    "acabamento_externo",
    "acabamento_ferramenta",
    "servicos",
    "entrega",
    "endereco",
    "observacao",
    "resumo",
    "fim",
  ];

  //Dados
  const [formData, setFormData] = useState({});
  const [open, setOpen] = useState(false);

  const handleGetCampos = async () => {
    // http://localhost:1337/api/pedidos/campos
    setIsLoading(true);
    await api
      .get("api/pedidos/campos")
      .then((resp) => {
        setCampos(resp.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // dsds
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetCampos();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNew = () => {
    setCurrentStep(steps[0]);
    setFormData({});
  };

  const handleBack = () => {
    // const totalSteps = steps.length;
    const currentPosition = steps.indexOf(currentStep);

    if (currentPosition > 0) setCurrentStep(steps[currentPosition - 1]);
  };

  const handleNextStep = (name_next) => {
    if (name_next) {
      setCurrentStep(name_next);
      return false;
    }

    const totalSteps = steps.length;
    const currentPosition = steps.indexOf(currentStep);

    if (currentPosition < totalSteps - 1)
      setCurrentStep(steps[currentPosition + 1]);
    // setCurrentStep(currentStep + 1);
  };

  const handleFormData = (campo, valor) => {
    setFormData({ ...formData, [campo]: valor });
  };

  return (
    <Layout>
      <AppBar position="static" color="primary" enableColorOnDark>
        <Toolbar>
          {currentStep !== "upload" && currentStep !== "fim" ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => handleBack()}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            ""
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            Novo Pedido
          </Typography>
          {currentStep !== "fim" && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: -2 }}
              onClick={() => setOpen(true)}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Dialog open={isLoading} sx={{ p: 3 }}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Quer cancelar o pedido?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja cancelar esse pedido?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Não</Button>
          <Button component={Link} to="/" autoFocus>
            Sim, Tenho certeza!
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
        {currentStep === "upload" && (
          <Upload
            value={formData.files}
            onNext={(files) => {
              handleFormData("files", files);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "materia_prima" && (
          <OneOption
            question="Qual a materia prima escolhida?"
            subtitle="Essa é uma explicação"
            options={campos.materia_prima}
            value={formData.materia_prima}
            onSelect={(value) => {
              handleFormData("materia_prima", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "materia_prima_tipo" && (
          <OneOption
            question="Qual a cor/tipo da aliança?"
            subtitle=""
            options={campos.materia_prima_tipo[formData.materia_prima.value]}
            value={formData.materia_prima_tipo}
            onSelect={(value) => {
              handleFormData("materia_prima_tipo", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "perfil" && (
          <OneOption
            question="Qual o modelo/perfil?"
            subtitle=""
            options={campos.perfil}
            value={formData.perfil}
            onSelect={(value) => {
              handleFormData("perfil", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "mais_info" && (
          <FormDetalhes
            data={formData.mais_info}
            options={campos.quantidade}
            onSubmit={(value) => {
              handleFormData("mais_info", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "acabamento_externo" && (
          <OneOption
            question="Escolha o acabamento externo?"
            subtitle=""
            options={campos.acabamento_externo}
            value={formData.acabamento_externo}
            onSelect={(value) => {
              handleFormData("acabamento_externo", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "acabamento_ferramenta" && (
          <MultiOption
            question="Escolha o acabamento de ferramenta?"
            subtitle="Você onde selecionar mais de uma opção."
            options={campos.acabamento_ferramenta}
            values={formData.acabamento_ferramenta}
            onSubmit={(value) => {
              handleFormData("acabamento_ferramenta", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "servicos" && (
          <Servicos
            data={formData}
            options={campos.servicos}
            onSubmit={(value) => {
              handleFormData("servicos", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "entrega" && (
          <OneOption
            question="Como deseja receber?"
            subtitle=""
            options={campos.entregas}
            value={formData.entrega}
            onSelect={(value) => {
              handleFormData("entrega", value);

              // console.log(value);

              if (value.endereco) {
                handleNextStep();
              } else {
                handleNextStep("observacao");
              }
            }}
          />
        )}

        {currentStep === "endereco" && (
          <Enderecos
            data={formData.endereco}
            onSubmit={(value) => {
              handleFormData("endereco", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "observacao" && (
          <TextArea
            question="Observação"
            subtitle="Se você tem algo que possa no auxiliar na produção, deixe aqui."
            value={formData.observacao}
            onSubmit={(value) => {
              handleFormData("observacao", value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "resumo" && (
          <Resumo
            data={formData}
            onSubmit={(value) => {
              setResultData(value);
              handleNextStep();
            }}
          />
        )}

        {currentStep === "fim" && (
          <Sucesso createNew={() => handleNew()} data={resultData} />
        )}
      </Box>
    </Layout>
  );
}
