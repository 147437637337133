import React from "react";

export default function Logo() {
  const forceSWupdate = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.update();
        }
      });
    }
  };

  return (
    <div>
      <img onClick={() => forceSWupdate()} src="/images/logo.png" alt="" />
    </div>
  );
}
