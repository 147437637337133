import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

import api from "../../../../services/api";

import { useAuth } from "../../../../context/AuthContext";

const style = {
  width: "100%",
  maxWidth: 500,
  bgcolor: "background.paper",
};

export default function Resumo({ data, onSubmit }) {
  const [concorda, setConcorda] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();

  const postPedido = async (data) => {
    setIsLoading(true);
    //Upload imagens
    const formData = new FormData();
    let imagens = [];

    Array.from(data.files).forEach((image) => {
      formData.append("files", image);
    });

    if (data.files.length > 0) {
      imagens = await api
        .post("/api/upload/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          // console.log(res);
          return res.data;
        })
        .catch((err) => {
          // console.log(err);
          return [];
        });
    }
    // Fim do Upload

    let info = {};
    info.imagens = imagens;
    info.materia_prima = parseInt(data.materia_prima.value);
    info.materia_prima_tipo = parseInt(data.materia_prima_tipo.value);
    info.perfil = parseInt(data.perfil.value);
    info.quantidade = parseInt(data.mais_info.quantidade);
    info.peso = Number(data.mais_info.peso);
    info.largura = Number(data.mais_info.largura);
    info.espessura = Number(data.mais_info.espessura);
    info.acabamento_externo = parseInt(data.acabamento_externo.value);
    info.alianca_1 = Number(data.mais_info.alianca1);
    info.alianca_2 = Number(data.mais_info.alianca2);
    info.acabamento_ferramentas = data.acabamento_ferramenta.map((item) =>
      parseInt(item.value)
    );
    info.servicos = data.servicos;
    info.entrega = parseInt(data.entrega.value);
    info.endereco = data.endereco;
    info.observacao = data.observacao;

    await api
      .post(
        "/api/pedidos",
        { data: info },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        // setCampos(resp.data);
        // console.log(resp.data);
        setIsLoading(false);
        onSubmit(resp.data);
      })
      .catch((err) => {
        // console.log(data);
        // console.log(err.response);
        setIsLoading(false);
      })
      .then(() => {
        // always executed
        setIsLoading(false);
      });
  };

  const handleSubmit = async () => {
    console.log(data);
    //Upload Imagens

    postPedido(data);
  };

  return (
    <Box noValidate sx={{ mt: 1, maxWidth: 600, width: "100%" }}>
      <Dialog open={isLoading} sx={{ p: 3 }}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        {data && data.files
          ? data.files.map((file, idx) => (
              <Avatar
                key={idx}
                alt={file.name}
                src={URL.createObjectURL(file)}
                sx={{ width: 90, height: 90 }}
                variant="rounded"
              />
            ))
          : "Sem imagens"}
      </Stack>

      <List dense sx={style} component="nav" aria-label="mailbox folders">
        <ListItem>
          <ListItemText sx={{ textAlign: "left" }}>
            <b>Características</b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Cliente" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>{user.nomeCompleto || "-"}</b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Matéria Prima" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.materia_prima && data.materia_prima.label
                ? data.materia_prima.label
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Tipo/Cor" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.materia_prima_tipo && data.materia_prima_tipo.label
                ? data.materia_prima_tipo.label
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Modelo/Perfil" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.perfil && data.perfil.label
                ? data.perfil.code
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Unidade/Par" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.mais_info && data.mais_info.quantidade
                ? data.mais_info.quantidade === "1"
                  ? "Unidade"
                  : "Par"
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Peso" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.mais_info && data.mais_info.peso
                ? data.mais_info.peso + "g"
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Largura" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.mais_info && data.mais_info.largura
                ? data.mais_info.largura + "mm"
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Espessura" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.mais_info && data.mais_info.espessura
                ? data.mais_info.espessura + "mm"
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Aliança 1" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.mais_info && data.mais_info.alianca1
                ? data.mais_info.alianca1 + " ⌀"
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        {data && data.mais_info && data.mais_info.alianca2 && (
          <ListItem>
            <ListItemText primary="Aliança 2" sx={{ textAlign: "left" }} />
            <ListItemText sx={{ textAlign: "right" }}>
              <b>
                {data && data.mais_info && data.mais_info.alianca2
                  ? data.mais_info.alianca2 + " ⌀"
                  : "-"}
              </b>
            </ListItemText>
          </ListItem>
        )}
        <Divider />
        <ListItem>
          <ListItemText primary="Acab. Ext." sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.acabamento_externo && data.acabamento_externo.label
                ? data.acabamento_externo.label
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Acab. Fer." sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data &&
                data.acabamento_ferramenta &&
                data.acabamento_ferramenta.map((acab) => acab.label).join(", ")}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        {data && data.servicos && (
          <>
            <ListItem>
              <ListItemText sx={{ textAlign: "left" }}>
                <b>Serviços</b>
              </ListItemText>
            </ListItem>
            <Divider />
          </>
        )}

        {data &&
          data.servicos &&
          data.servicos.map((serv, idx) => (
            <React.Fragment key={idx}>
              <ListItem key={idx}>
                <ListItemText
                  primary={<b>{serv.info.label}</b>}
                  secondary={
                    <div>
                      {serv.servico_tipo_info &&
                      serv.servico_tipo_info.attributes &&
                      serv.servico_tipo_info.attributes.nome ? (
                        <>
                          - {serv.servico_tipo_info.attributes.nome}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <b>Aliança 1 ({data.mais_info.alianca1}):</b>{" "}
                      {serv.alianca_1}
                      <br />
                      {serv.alianca_2 && (
                        <>
                          <b>Aliança 2({data.mais_info.alianca2}):</b>{" "}
                          {serv.alianca_2}
                        </>
                      )}
                      {serv.obs && <>{serv.obs}</>}
                    </div>
                  }
                />
              </ListItem>
              <Divider key={idx + "-" + serv.servico} />
            </React.Fragment>
          ))}
        <ListItem>
          <ListItemText
            primary={<b>Observações Finais</b>}
            secondary={data.observacao || "-"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText sx={{ textAlign: "left" }}>
            <b>Entrega</b>
          </ListItemText>
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {data && data.entrega && data.entrega.label
                ? data.entrega.label
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        {data && data.endereco && (
          <>
            <ListItem>
              <ListItemText
                primary="Endereço"
                secondary={data.endereco.endereco || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Número"
                secondary={data.endereco.numero || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Complemento"
                secondary={data.endereco.complemento || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Bairro"
                secondary={data.endereco.bairro || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Cidade"
                secondary={data.endereco.cidade || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Estado"
                secondary={data.endereco.estado || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="CEP"
                secondary={data.endereco.cep || "-"}
              />
            </ListItem>
            <Divider />
          </>
        )}
      </List>

      <FormControlLabel
        control={
          <Checkbox
            checked={concorda}
            onClick={() => setConcorda(!concorda)}
            value="allowExtraEmails"
            color="primary"
          />
        }
        size="small"
        label={`Eu, ${user.nomeCompleto}, confirmo todas as informações inseridas acima e autorizo a confecção deste produto`}
      />

      <FormControl fullWidth sx={{ mt: 5 }}>
        {concorda && (
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            size="medium"
          >
            Concluir
          </Button>
        )}
      </FormControl>
    </Box>
  );
}
