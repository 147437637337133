import React, { useEffect, useState } from "react";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

import { useDropzone } from "react-dropzone";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  justifyContent: "center",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const deleteImg = {
  display: "block",
  position: "absolute",
  right: "center",
  top: "center",
};

const dropzone = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export default function Etapa({ value, onNext }) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    maxSize: 3000000,
    multiple: true,
    onDrop: (acceptedFiles) => {
      const totalFiles = files.length;
      // const totalNewFiles = acceptedFiles.length;
      const maxFiles = 5;

      if (totalFiles >= maxFiles) {
        return true;
      }

      setFiles([...files, ...acceptedFiles]);
    },
  });

  const deleteImage = (position) => {
    const newFiles = [...files]; // make a var for the new array
    newFiles.splice(position, 1); // remove the file from the array
    setFiles(newFiles);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    // console.log(files);
  }, [files]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (value) setFiles(value);
  }, [value]);

  const handleNext = () => {
    onNext(files);
  };

  return (
    <Box noValidate sx={{ mt: 1 }}>
      <Typography sx={{ textAlign: "center", alignItems: "center", mt: 5 }}>
        Foto do Pedido
      </Typography>
      <FormControl fullWidth sx={{ mt: 5, mb: 5, alignItems: "center" }}>
        <section className="container">
          <div {...getRootProps({ style: dropzone })}>
            <input {...getInputProps()} />
            <p>Clique aqui para selecionar uma imagem</p>
          </div>
          <aside style={thumbsContainer}>
            {files.map((file, idx) => (
              <div style={thumb} key={idx}>
                <div style={thumbInner}>
                  <Button onClick={() => deleteImage(idx)} style={deleteImg}>
                    X
                  </Button>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    style={img}
                  />
                </div>
              </div>
            ))}
          </aside>
        </section>
      </FormControl>

      <Button
        onClick={() => handleNext()}
        fullWidth
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
      >
        Avançar
      </Button>
    </Box>
  );
}
