import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Layout from "../../../layouts/default";
import { Link as RouterLink } from "react-router-dom";

// import api from "../../../services/api";

// Formulário
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useAuth } from "../../../context/AuthContext";

const schema = Yup.object({
  email: Yup.string()
    .email("Insira um e-mail válido")
    .required("Insira seu e-mail"),
});

export default function SignIn() {
  const { loading, forgotPassword } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // console.log(data);
    // console.log(data.email);

    forgotPassword(data.email);
    setValue("email");
  };

  return (
    <Layout sx={{ pl: 2, pr: 2 }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Recuperar Senha
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            error={errors.email ? true : false}
            {...register("email")}
            helperText={errors && errors.email && errors.email.message}
          />
          {loading ? (
            <LoadingButton fullWidth loading variant="outlined">
              Recuperando...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Recuperar Senha
            </Button>
          )}

          <Grid container>
            <Grid item>
              <Link component={RouterLink} to="/auth/login" variant="body2">
                {"Voltar"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
}
