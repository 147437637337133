import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function TextArea({ question, subtitle, onSubmit, value }) {
  const valueRef = useRef("");
  const [textAreaContent, setTextAreaContent] = useState("");

  useEffect(() => {
    if (value) setTextAreaContent(value);
  }, [value]);

  const handleSubmit = () => {
    // console.log(valueRef.current.value);
    // console.log(selectedOptions);
    onSubmit(valueRef.current.value);
  };

  return (
    <Box noValidate sx={{ mt: 1, width: "100%" }}>
      <Typography sx={{ mt: 1, mb: subtitle ? 0 : 3, fontWeight: "bold" }}>
        {question}
      </Typography>
      {subtitle && (
        <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>{subtitle}</Typography>
      )}
      <FormControl fullWidth>
        <TextField
          defaultValue={textAreaContent}
          minRows={3}
          type="text"
          multiline
          inputRef={valueRef}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 5 }}>
        <Button
          onClick={() => handleSubmit()}
          variant="contained"
          size="medium"
        >
          Avançar
        </Button>
      </FormControl>
    </Box>
  );
}
