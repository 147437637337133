import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Layout from "../../../layouts/default";

import { Link as RouterLink, useSearchParams } from "react-router-dom";

// import api from "../../../services/api";

// Formulário
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useAuth } from "../../../context/AuthContext";

const schema = Yup.object({
  password: Yup.string()
    .required("Por favor insira uma senha")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "A senha teve conter no mínimo 8 caracteres, um Maiúsculo, um minusculo, um número e um caracter especial"
    ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "A confirmação de senha deve ser igual à senha"
  ),
});

export default function SignIn() {
  // const [code,setCode]
  const { loading, resetPassword } = useAuth();

  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // console.log(data);
    // console.log(data.email);
    // resetPassword(data.email);
    // console.log(searchParams.get("code"));
    const code = searchParams.get("code");

    if (code) resetPassword(code, data.password);
    setValue("password", "");
  };

  return (
    <Layout sx={{ pl: 2, pr: 2 }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Digite sua nova senha
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            type="password"
            fullWidth
            id="password"
            label="Senha"
            name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {/* {values.showPassword ? <VisibilityOff /> : <Visibility />} */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoFocus
            error={errors.password ? true : false}
            {...register("password")}
            helperText={errors && errors.password && errors.password.message}
          />

          <TextField
            margin="normal"
            type="password"
            fullWidth
            id="passwordConfirmation"
            label="Confirmar Senha"
            name="passwordConfirmation"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {/* {values.showPassword ? <VisibilityOff /> : <Visibility />} */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoFocus
            error={errors.passwordConfirmation ? true : false}
            {...register("passwordConfirmation")}
            helperText={
              errors &&
              errors.passwordConfirmation &&
              errors.passwordConfirmation.message
            }
          />
          {loading ? (
            <LoadingButton fullWidth loading variant="outlined">
              Recuperando...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Recuperar Senha
            </Button>
          )}

          <Grid container>
            <Grid item>
              <Link component={RouterLink} to="/auth/login" variant="body2">
                {"Voltar"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
}
