import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function OneOption({
  question,
  subtitle,
  options,
  onSelect,
  value,
}) {
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleSelect = (value) => {
    setSelected(value);
    // console.log(options);
    setTimeout(() => {
      onSelect(value);
    }, 200);
  };

  return (
    <Box noValidate sx={{ mt: 1, maxWidth: 300, width: "100%" }}>
      <Typography sx={{ mt: 1, mb: subtitle ? 0 : 3, fontWeight: "bold" }}>
        {question}
      </Typography>
      {subtitle && (
        <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>{subtitle}</Typography>
      )}

      {options &&
        options.map((option, idx) => (
          <Button
            sx={{
              mt: 1,
              mb: 1,
              justifyContent: option.uri ? "left" : "center",
            }}
            key={idx}
            onClick={() => handleSelect(option)}
            fullWidth
            variant={
              selected && selected.value === option.value
                ? "contained"
                : "outlined"
            }
            startIcon={
              option.uri && (
                <Avatar
                  src={`/images/uris/${option.uri}.png`}
                  variant="square"
                />
              )
            }
          >
            {option.label}
          </Button>
        ))}
    </Box>
  );
}
