const dev = process.env.NODE_ENV !== "production";

//BaseIMage são os assets

const config = {
  baseApi: dev
    ? "http://localhost:1337"
    : "https://fabrica.diegoassuncao.com.br",
  colors: {
    primary: "#000000",
  },
};

export default config;
