import React, { createContext, useCallback, useState, useContext } from "react";

import { toast } from "react-toastify";

import api from "../services/api";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem("@Auth:token");
    const user = localStorage.getItem("@Auth:user");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const [loading, setLoading] = useState(false);

  const signUp = useCallback(async (data) => {
    setLoading(true);

    const sucessOrNot = await api
      .post("api/auth/local/register", {
        ...data,
        blocked: true,
      })
      .then((resp) => {
        // const { jwt: token, user } = resp.data;

        // Não vai ficar logado, precisa de confirmação
        // localStorage.setItem("@Auth:token", token);
        // localStorage.setItem("@Auth:user", JSON.stringify(user));

        // api.defaults.headers.authorization = `Bearer ${token}`;

        // setData({ token, user });
        setLoading(false);
        // navigate("/", { replace: true });

        toast.success("Você foi cadastrado com sucesso! Aguarde Aprovação");
        // return <Navigate to="/" />;

        return true;
      })
      .catch((err) => {
        setLoading(false);
        // console.log("-->", err.response.data.error.message);
        toast.error(err.response.data.error.message);
        return false;
      });

    return sucessOrNot;
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    setLoading(true);
    api
      .post("api/auth/local", {
        identifier: email,
        password: password,
      })
      .then((resp) => {
        const { jwt: token, user } = resp.data;

        localStorage.setItem("@Auth:token", token);
        localStorage.setItem("@Auth:user", JSON.stringify(user));

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ token, user });
        setLoading(false);
        // window.location = "/";
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Usuário e/ou Senha estão incorretos!");
      });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@Auth:token");
    localStorage.removeItem("@Auth:user");

    api.defaults.headers.authorization = "";

    setData({});
  }, []);

  const forgotPassword = useCallback((email) => {
    setLoading(true);
    api
      .post("api/auth/forgot-password", {
        email,
      })
      .then((resp) => {
        setLoading(false);
        toast.success("Link para alterar senha foi enviado para seu e-mail!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("E-mail não encontrado!");
      });
  }, []);

  const resetPassword = useCallback((code, password) => {
    setLoading(true);
    api
      .post("api/auth/reset-password", {
        code: code, // code contained in the reset link of step 3.
        password: password,
        passwordConfirmation: password,
      })
      .then((resp) => {
        setLoading(false);
        toast.success("Senha alterada com sucesso!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          "Ocorreu um erro e sua senha não foi recriada, entre em contao ou gere um novo link!"
        );
      });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        signIn,
        signOut,
        forgotPassword,
        resetPassword,
        signUp,
        user: data.user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth deve ser usado dentro de AuthContext");
  }

  return context;
};

export { AuthProvider, useAuth };
