import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";

export default function CheckBox({
  data,
  service,
  includedServices,
  onSubmit,
  onCancel,
}) {
  const [alianca1, setAlianca1] = useState("");
  const [alianca2, setAlianca2] = useState("");

  const handleSubmit = () => {
    let info = {};

    info.servico = Number(service.value);
    info.info = service;
    info.tipo = "descricao";
    info.obs = "";
    info.alianca_1 = alianca1;
    info.alianca_2 = alianca2;

    onSubmit(info);
  };

  useEffect(() => {
    const selecionado = includedServices.find(
      (item) => item.servico === service.value
    );

    if (selecionado) {
      if (selecionado.alianca_1) setAlianca1(selecionado.alianca_1);
      if (selecionado.alianca_2) setAlianca2(selecionado.alianca_2);
    }
  }, [includedServices, service]);

  return (
    <Box noValidate sx={{ mt: 1, maxWidth: 300, width: "100%" }}>
      <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
        {service.label || "-"}
      </Typography>
      <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
        {service.ajuda || "Descreva todos os detalhes"}
      </Typography>
      {data.mais_info.alianca1 && (
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            label={`Aliança 1 - Nº ${data.mais_info.alianca1}`}
            fullWidth
            value={alianca1}
            onChange={(event) => {
              setAlianca1(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      )}
      {data.mais_info.alianca2 && (
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            label={`Aliança 2 - Nº ${data.mais_info.alianca2}`}
            fullWidth
            value={alianca2}
            onChange={(event) => {
              setAlianca2(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      )}

      <FormControl fullWidth sx={{ mb: 3 }}>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          color="secondary"
          size="medium"
          onClick={() => handleSubmit()}
        >
          Salvar
        </Button>
        <Button variant="contained" size="medium" onClick={() => onCancel()}>
          Cancelar
        </Button>
      </FormControl>
    </Box>
  );
}
