import React from "react";
import Routes from "./routes";

import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

import AppProvider from "./context";

function App() {
  return (
    <>
      <ToastContainer />
      <AppProvider>
        <Routes />
      </AppProvider>
    </>
  );
}

export default App;
